<template>
  <div v-if="id" :class="{dotted:!(!person[prefix + 'Rel'] || person[prefix + 'Rel'] === '2') || person.FamilyRel}">
    <person-link :person="person" :prefix="prefix" :class="[root, 'person']" :style="{width: treeWidths[generation] + 'px'}">
        <div v-for="d in treeData[id]" :key="d" v-html="d"></div>
    </person-link>
    <div v-if="parentFamilies.length>0&&person.Gen<maxGen" :class="[root, 'branch']" :style="{marginLeft: treeWidths[generation]+50 + 'px'}">
        <template v-for="parents in parentFamilies" :key="parents">
            <person-tree-node :maxGen="maxGen" :ancestors="ancestors" :person="parents" :prefix="'Father'" />
            <person-tree-node :maxGen="maxGen" :ancestors="ancestors" :person="parents" :prefix="'Mother'"/>
        </template>
    </div>
    <div v-else-if="parentFamilies.length>0" class="next" :style="{marginLeft: treeWidths[generation]-9 + 'px'}">
        <person-link :person="person" :prefix="prefix" addition=".Antavla">▸</person-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maxGen: null,
    ancestors: null,
    person: null,
    prefix: {
      default: '',
      type: String
    },
    root: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    id () {
      return this.person[this.prefix + 'Id']
    },
    generation () {
      return this.root ? 1 : this.$parent.generation + 1
    },
    parentFamilies () {
      const anc = this.ancestors[this.generation]
      return anc ? anc.filter(i => i.ChildId === this.id) : []
    }
  }
}
</script>
<style lang="scss">
@use 'sass:math';

$horizontal-gutter: 50px;
$border-radius: 10px;

$entry-min-height: 95px;

$label-width: 200px;
$label-height: 25px;
$label-border-radius: 5px;

.branch {
  &.start > div:after{
    display:none;
  }
  &:before {
    content: "";
    width: math.ceil(math.div($horizontal-gutter,2));
    border-top: 2px solid $MASTER_COLOR;
    position: absolute;
    left: -$horizontal-gutter;
    top: 50%;
    margin-top: 1px
  }
  > div {
    &.dotted a {
      opacity: 0.4
    }
    &:before {
      content: "";
      height: 100%;
      border-left: 2px solid $MASTER_COLOR;
      position: absolute;
      left: - math.ceil(math.div($horizontal-gutter,2))
    }
    &:after {
      content: "";
      width: math.ceil(math.div($horizontal-gutter,2));
      border-top: 2px solid $MASTER_COLOR;
      position: absolute;
      left: - math.ceil(math.div($horizontal-gutter,2));
      top: 50%;
      margin-top: 1px
    }
    &:first-child {
      &:before {
        width: $border-radius;
        height: 50%;
        top: 50%;
        margin-top: 2px;
        border-radius: $border-radius 0 0 0
      }
      &:after {
        height: $border-radius;
        border-radius: $border-radius 0 0 0
      }
    }
    &:last-child {
      &:before {
        width: $border-radius;
        height: 50%;
        border-radius: 0 0 0 $border-radius
      }
      &:after {
        height: $border-radius;
        border-top: none;
        border-bottom: 2px solid $MASTER_COLOR;
        border-radius: 0 0 0 $border-radius;
        margin-top: -$border-radius + 1px
      }
    }
    &:only-child {
      &:before {
        display: none
      }
      &:after {
        width: math.ceil(math.div($horizontal-gutter,2));
        height: 0;
        margin-top: 1px;
        border-radius: 0
      }
    }
    position: relative;
    min-height: $entry-min-height
  }
  position: relative
}

.person {
  &:hover {
    background-color: lighten($MASTER_COLOR, 50%);
    text-decoration: none
  }
  div {
    &:first-child {
      color: $MASTER_COLOR;
      font-size: 12px;
      font-weight:bold
    }
  }
  font-size: 10px;
  border: 1px solid #ccc;
  box-shadow: 4px 4px 4px rgba(67,67,67,.4);
  padding: 5px;
  text-align: center;
  border-radius: 6px;
  color: #000;
  line-height: $label-height - 5px * 2;
  position: absolute;
  top: 50%;
  margin-top: - math.ceil(math.div($entry-min-height,2));
  -webkit-text-size-adjust: 100%
}
.next {
  position: absolute;
  top: 20%;
  font-size: 35px;
  a:hover {
     text-decoration: none;
     color: lighten($MASTER_COLOR, 20%)
  }
}
</style>
