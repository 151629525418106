<template>
    <Head>
      <title>{{title}} - {{websiteTitle}}</title>
      <meta name="description" :content="description"/>
    </Head>
    <article v-html="content"></article>
</template>
<script>
export default {
  data () {
    return {
      content: null,
      title: null,
      description: null
    }
  },
  beforeRouteEnter (to, _from, next) {
    next(vm => {
      vm.description = to.meta.description
      vm.title = to.meta.title
      fetch('/assets/' + to.path + '.html').then(response => response.text()).then(content => (vm.content = content))
    })
  }
}
</script>
