<template>
<Head>
  <title>
    Kontakt - {{websiteTitle}}
  </title>
  <meta name="description" content="Kontakta mig för frågor, rättelser eller kompletteringar."/>
</Head>
<article class="contact">
    <div>
        Kontakta mig gärna om du har frågor,<br/> kompletteringar eller rättelser!
    </div>
    <div v-if="contactInfo">
        <div v-for="contact in contactInfo" :key="contact">
            <a :href="contact.url" target="_blank"><img :title="contact.name" :src="'/img/' + contact.icon"/><div>{{contact.name}}</div></a>
        </div>
    </div>
    <div v-else>
        <button class="g-recaptcha" @click="showContactInfo()" :data-sitekey="recaptchaKey" data-callback="verifyCallback"><img title="E-post" src="/img/mail.png"/></button>
        <p>(spamskydd)</p>
    </div>
</article>
</template>
<script>
import { getCurrentInstance } from '@vue/runtime-core'
import { useHead } from '@vueuse/head'
import Fetch from '../mixins/fetch'
let cResponse = null
export default {
  mixins: [Fetch],
  setup () {
    const internalInstance = getCurrentInstance()
    internalInstance.appContext.config.globalProperties.captchaPromise = new Promise((resolve) => {
      window.verifyCallback = (response) => {
        cResponse = response
        resolve(window.capi)
      }
    })
    useHead({
      script: {
        type: 'text/javascript',
        src: 'https://www.google.com/recaptcha/api.js'
      }
    })
  },
  data () {
    return {
      contactInfo: null,
      response: null,
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY
    }
  },
  methods: {
    showContactInfo () {
      this.captchaPromise.then(i => {
        this.fetchData('/captcha?g-recaptcha-response=' + cResponse).then(data => {
          this.contactInfo = data.contact
        })
      })
    }
  }
}
</script>
<style lang="scss">
.contact {
    text-align: center;
    padding-top:10px
}
.g-recaptcha{
    background:none!important;
    -webkit-appearance: none
}
</style>
